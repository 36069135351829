@import '~bootstrap/scss/bootstrap.scss';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);

// Bootstrap
$font-family-sans-serif: 'Roboto', sans-serif;

$carousel-control-width: auto;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-control-icon-width: 28px;

$grid-breakpoints: (
  xs: 0,
  sm: 880px,
  md: 881px,
  lg: 1120px,
  xl: 1300px,
);

// Custom
$bottom-navigation-height: 56px;

html,
body {
  // background-color: white;
}

.bordertopwieh0tab {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

a:link,
a:hover,
a:active {
  color: #616161;
}

header {
  width: 100%;
  height: 65px;
  background: rgb(60, 20, 120);
  background: linear-gradient(
    90deg,
    rgba(60, 20, 120, 1) 0%,
    rgba(254, 0, 102, 1) 90%
  );
}

.cursor {
  cursor: pointer;
}

.mini-box {
  width: fit-content;
  height: 31px;
  border: 1px solid #d0d4dd;
  border-radius: 4px;
  padding: 10px 12px 10px 12px;
  text-align: center;
}

.bg-body {
  // background-color: #f5f8fd;
}

.foo {
  width: 100%;
  height: 125px;
}

.full-height {
  height: 100%;
  min-height: 100%;
}

.maxWidth {
  max-width: 1600px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
.inputs {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 10px;
  border-bottom: 2px solid #d3d3d3;
  box-shadow: none;
  color: #111;
}
.inputs:invalid {
  outline: 0;
}
.inputs:focus,
.inputs:valid {
  border-color: #43a9f0;
}
.inputs:focus ~ .labels,
.inputs:valid ~ .labels {
  font-size: 12px;
  top: -24px;
}

.log-info {
  color: #fff;
  font-size: 14px;
}

.rounded-circle {
  margin-left: 10px;
  max-width: 47px;
  max-height: 47px;
}

.live-title {
  font-family: 'Roboto Condensed';
  font-size: 35px;
  font-weight: 700;
  color: #191919;
  line-height: 1;
}

.live-title-schdl {
  font-family: 'Roboto Condensed';
  font-size: 24px;
  font-weight: 700;
  color: #191919;
  line-height: 1;
}

.icon-live {
  width: 100%;
  max-width: 55px;
  min-height: 30px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  background-color: #fd3944;
  border-radius: 30px 30px 30px 0px;
}

.icon-date {
  width: 100%;
  max-width: 69px;
  max-height: 69px;
  font-size: 21px;
  text-align: center;
  line-height: 1;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  background-color: #fd3944;
  border-radius: 0px 20px 20px 20px;
}

.icon-hour {
  width: 100%;
  max-width: 69px;
  max-height: 69px;
  font-family: 'Roboto Condensed';
  font-size: 21px;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  background-color: #458aff;
  border-radius: 0px 20px 20px 20px;
}

.texts {
  font-size: 14px;
  color: #9ba1ac;
}

.sec-texts {
  font-size: 14px;
  color: #616161;
}

.name-ppl {
  font-size: 15px;
  font-weight: 700;
  color: #272727;
  border-bottom: 1px solid #969696;
  padding-bottom: 15px;
}

.events {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  font-size: 21px;
  color: #fe0066;
}

.title-event-schdl {
  font-size: 14px;
  font-weight: 700;
  color: #272727;
}

.date-event-schdl {
  font-family: 'Roboto Condensed';
  font-size: 16px;
  font-weight: 700;
  color: #f00066;
  line-height: 1;
}

.bg-fscreen {
  background-color: #034cd9;
  min-width: 36px;
  min-height: 36px;
}

.panel-title a {
  &:after {
    @extend %fa-icon;
    @extend .fas;
    content: fa-content($fa-var-chevron-down);
    float: right;
    font-size: 10px;
    padding-right: 13px;
  }
}

.panel-title a.collapsed {
  &:after {
    @extend %fa-icon;
    @extend .fas;
    content: fa-content($fa-var-chevron-up);
  }
}

.bp-box {
  width: 100%;
  height: 10vh;
  overflow-y: scroll;
}

.bp-title {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #212121;
  border-bottom: 1px solid rgba($color: #707070, $alpha: 0.1);
  padding-bottom: 15px;
}

.bp-title-user {
  font-size: 14px;
  font-weight: 700;
  color: #191919;
}

.bp-img {
  margin-right: 10px;
  max-width: 24px;
  max-height: 24px;
}

#bp-send {
  width: 100%;
  height: 45px;
  padding: 10px;
  position: relative;
  border: 1px solid #d0d4dd;
  border-radius: 4px;
  font-size: 14px;
  color: #707070;
}

.divider-sender {
  border-top: 1px solid rgba($color: #707070, $alpha: 0.1);
}

#icone_send {
  position: relative;
  left: 90%;
  top: -35px;
  color: #034cd9;
  cursor: pointer;
}

.title-banner {
  font-family: 'Roboto Condensed';
  font-size: 33px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}

.video-strm {
  width: 100%;
  height: 477px;
  background: rgb(60, 20, 120);
  background: linear-gradient(
    90deg,
    rgba(60, 20, 120, 1) 0%,
    rgba(254, 0, 102, 1) 90%
  );
}

.card-no-border {
  border: unset;
}

.btn-feed {
  width: 100%;
  max-width: 128px;
  height: 34;
  text-align: center;
  border: 1px solid #034cd9;
  border-radius: 4px;
  font-size: 14px;
  color: #034cd9;
}

.bottom-navigation {
  height: $bottom-navigation-height;

  .active {
    color: #034cd9;
  }

  font-size: 12px;
}

.bottom-navigation-collapse {
  z-index: 1040;
  position: absolute;
  bottom: 55px;
  right: 10px;
}

.keep-16-9-ratio {
  position: relative;
}

#top-header {
  height: 48px;
}

.side-menu {
  position: absolute;
  right: -75px !important;
}

.carousel-control-icon {
  filter: drop-shadow(0 0 3px #333);
  font-size: $carousel-control-icon-width;
}

.carousel-control-prev,
.carousel-control-next {
  bottom: unset;
  // top: 20%;
  top: 130/2 - $carousel-control-icon-width/2;
}

#player-expanded-background {
  position: absolute;
  left: 0;
  width: 100%;
}

html,
body {
  height: 100%;
}

// #image-bg {
//   background: url('images/banner.svg');
//   background-repeat: no-repeat;
//   background-size: cover;
// }

/* Borda de divisão do logotipo */
.b-b {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
}

/* Logotipo da plataforma no login */
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 200px;
  max-height: 80px;
}

/* Título da plataforma no login */
.login-title {
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  color: #272727;
}

/* Texto "Faça o seu login!" */
.login-text {
  font-size: 15px;
  text-align: center;
  color: #272727;
}

/* Tamanho padrão do formulário */
.form-s {
  width: 100%;
  max-width: 459px;
}

/* Texto do placeholder do formulário" */
.form-text {
  font-size: 15px;
  color: #969696;
}

/* Campos do formulário */
#log-form .form-control {
  border: 1px solid #d0d4dd;
  border-radius: 4;
  height: 50px;
}

/* Botão de Login */
.login-button {
  width: 100%;
  height: 50px;
  font-size: 15px;
  font-weight: 700;
  background-color: #034cd9;
  text-transform: uppercase;
  margin-top: 10px;
}

/* Texto Esqueceu a Senha */
.forgot-text {
  font-size: 15px;
  color: #616161;
  text-decoration: underline;
}

/* Imagem do Banner de entrada do login */
.banner-img {
  height: 100vh;
  width: auto;
  max-width: 528px;
  margin: auto;
}

@include media-breakpoint-down(sm) {
  .divider-sender {
    position: absolute;
    width: 95%;
    bottom: 0;
    left: 10px;
  }
}

@include media-breakpoint-down(sm) {
  body {
    main {
      height: calc(100% - 104px); // top header + bottom nav
    }
  }
}

@include media-breakpoint-up(md) {
  #top-header {
    height: 64px;
  }
}

@include media-breakpoint-down(sm) {
  /* a container with flex-direction column */
  .main-container {
    position: absolute;
    height: calc(100% - 168px);
    display: flex;
    flex-direction: column;
  }

  /* this is the flex container that will take the rest of the height */
  .content-wrapper {
    display: flex;
    flex: 1;
    min-height: 0px;
    /* IMPORTANT: you need this for non-chrome browsers */
  }

  /* the container for our overflowed content */
  .overflow-container {
    flex: 1;
    overflow: auto;
  }

  .log-info {
    font-size: 12px;
  }
}

@include media-breakpoint-up(md) {
  .overflow-container {
    height: 550px;
    overflow: auto;
  }
}

@include media-breakpoint-down(sm) {
  #image-bg {
    height: 40vh;
  }
}

.viewLangs {
  display: flex;
  flex-direction: row;
  padding: 7px;
  justify-content: center;
  align-items: center;
}
