.tables-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.table-container{
  display: flex;
  flex-direction: column;
}
h3{
  align-self: center;
  height: 50px;
}
table{
  align-self: center;
}
td{
  height: 50px;
  width: 100px;
  font-size: 12px;
}
table,td,rd{
  border: 1px solid black;
  text-align: center;
}
@media (max-width: 1450px) {
  .tables-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  h3{
    align-self: center;
    height: 50px;
    margin-top: 20px;
    font-size: 23px;
  }
}

