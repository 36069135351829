.header {
  width: 100%;
  max-height: fit-content;
  color: #636363;
  /* background: rgba(0, 0, 0, 0.2); */
  display: flex;
}
.header-content {
  max-width: 1745px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  padding: 0 1rem;
}
.header_language {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_left {
  display: flex;
  flex: 1;
  max-width: fit-content;
  margin-right: 64px;
  /* justify-content: space-evenly; */
}
.header_left h3 {
  font-size: 16px;
  margin: 0 0 0 5px;
  color: #fff;
  font-weight: 400;
}
.header_left > img {
  height: 40px;
}

.header_center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
}

.header_option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.header_option path {
  height: 28px;
  fill: #fff;
}
.header_option a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  color: #fff;
  font-size: 10px;
  font-weight: light;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.header_option > a::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: transparent;

  transform: scaleX(0.9);
  opacity: 0.6;
}

.header_option > a.active::after,
.header_option:hover > a::after {
  background: #fff;
  animation: show 0.1s linear forwards;
}

.profile-menu-relative-container.inactive {
  display: none;
}

.profile-menu-relative-container.active {
  display: block;
  visibility: visible;
  transform: translateY(0);
}
#profile-menu {
  display: inline-block;
  position: absolute;
  right: -93px;
  top: 40px;
  background-color: #fff;
  margin-right: 100px;
  width: 300px;
  border-radius: 5px;
}
.profile-menu-shadow.multiply {
  width: 300px;
  z-index: 1;
  mix-blend-mode: multiply;
  transition: all 250ms;
  box-shadow: 0 0 5px rgb(100 106 132 / 50%), 0 5px 12px rgb(100 106 136 / 80%);
  border-radius: 5px;
  padding: 10px 10px;
  background: #bc076c;
}

.profile-menu-shadow > a {
  color: #fff;
  margin: 0;
  display: block;
  padding: 5px 0px 5px 30px;
}
.blockMobile svg {
  margin: 0 2px;
}

.header_option a > svg {
  font-size: 25px;
}
.iconLogout {
  font-size: 20px;
  line-height: 1;
}

.header_lenguage,
.header_info {
  display: flex;
  align-items: center;
}
/* .header_info img {
  max-width: 141px;
  width: auto;
  max-height: 25px;
} */

.header_lenguage > p,
.header_info > p {
  margin-left: 10px;
  margin-bottom: 0;
  margin-right: 5px;
}

.header_right {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
}

.blockMobile {
  display: none !important;
}

.siderbar-div{
  color: #616161;
  width: 100%;
}
.siderbar-div:hover{
  color: #02C90F;
  width: 100%;

}
.siderbar-div > *:hover {
  fill: #02C90F;
}
/**/
@media only screen and (max-width: 992px) {
  .header {
    grid-template-columns: repeat(2, auto);
  }
  .header_center {
    display: none;
  }
  .blockMobile {
    display: block !important;
  }
  @media (max-width: 1024px) {
    .header_left {
      margin-right: initial;
    }
  }
}

@media only screen and (max-width: 1000px) {
  p.nameEmailUser {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 11ch;
  }
}
